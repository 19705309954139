var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pvhFactory" },
    [
      _c(
        "basic-container",
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("ADD"),
                visible: _vm.addVisible,
                fullscreen: "",
                "append-to-body": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.addVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.addForm,
                    rules: _vm.rules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("CODE"), prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.code,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "code", $$v)
                          },
                          expression: "addForm.code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("NAME"), prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "name", $$v)
                          },
                          expression: "addForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("INVENTORY TYPE"), prop: "type" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.addForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "type", $$v)
                            },
                            expression: "addForm.type"
                          }
                        },
                        _vm._l(_vm.inventoryTypeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("CLASSIFICATION VALUE"),
                        prop: "scaleFactor"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.addForm.scaleFactor,
                          callback: function($$v) {
                            _vm.$set(_vm.addForm, "scaleFactor", $$v)
                          },
                          expression: "addForm.scaleFactor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("Product Classification Group"),
                        prop: "properties"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("Pleaseselect"),
                            clearable: ""
                          },
                          model: {
                            value: _vm.addForm.productCategoryTypeId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.addForm,
                                "productCategoryTypeId",
                                $$v
                              )
                            },
                            expression: "addForm.productCategoryTypeId"
                          }
                        },
                        _vm._l(_vm.typeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("Material Properties"),
                        prop: "properties"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            placeholder: _vm.$t("Pleaseselect"),
                            clearable: ""
                          },
                          model: {
                            value: _vm.addForm.propertieList,
                            callback: function($$v) {
                              _vm.$set(_vm.addForm, "propertieList", $$v)
                            },
                            expression: "addForm.propertieList"
                          }
                        },
                        _vm._l(_vm.properties, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.addVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.addSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.$t("Edit"),
                visible: _vm.editVisible,
                fullscreen: "",
                "append-to-body": true
              },
              on: {
                "update:visible": function($event) {
                  _vm.editVisible = $event
                }
              }
            },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.editForm,
                    rules: _vm.rules,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("CODE"), prop: "code" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editForm.code,
                          callback: function($$v) {
                            _vm.$set(_vm.editForm, "code", $$v)
                          },
                          expression: "editForm.code"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("NAME"), prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.editForm, "name", $$v)
                          },
                          expression: "editForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: _vm.$t("INVENTORY TYPE"), prop: "type" }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.editForm.type,
                            callback: function($$v) {
                              _vm.$set(_vm.editForm, "type", $$v)
                            },
                            expression: "editForm.type"
                          }
                        },
                        _vm._l(_vm.inventoryTypeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("CLASSIFICATION VALUE"),
                        prop: "scaleFactor"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.editForm.scaleFactor,
                          callback: function($$v) {
                            _vm.$set(_vm.editForm, "scaleFactor", $$v)
                          },
                          expression: "editForm.scaleFactor"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("Product Classification Group"),
                        prop: "properties"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            placeholder: _vm.$t("Pleaseselect"),
                            clearable: ""
                          },
                          model: {
                            value: _vm.editForm.productCategoryTypeId,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.editForm,
                                "productCategoryTypeId",
                                $$v
                              )
                            },
                            expression: "editForm.productCategoryTypeId"
                          }
                        },
                        _vm._l(_vm.typeList, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("Material Properties"),
                        prop: "properties"
                      }
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: {
                            multiple: "",
                            placeholder: _vm.$t("Pleaseselect")
                          },
                          model: {
                            value: _vm.editForm.propertieList,
                            callback: function($$v) {
                              _vm.$set(_vm.editForm, "propertieList", $$v)
                            },
                            expression: "editForm.propertieList"
                          }
                        },
                        _vm._l(_vm.properties, function(item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          _vm.editVisible = false
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("Cancel")))]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.editSubmit }
                    },
                    [_vm._v(_vm._s(_vm.$t("Yes")))]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "avue-crud",
            {
              ref: "crud",
              attrs: {
                option: _vm.tableOption,
                data: _vm.tableData,
                "table-loading": _vm.tableLoading,
                page: _vm.page
              },
              on: {
                "update:page": function($event) {
                  _vm.page = $event
                },
                "current-change": _vm.currentChange,
                "size-change": _vm.sizeChange,
                "selection-change": _vm.selectionChange,
                "refresh-change": _vm.refreshChange,
                "row-save": _vm.handleSave,
                "row-update": _vm.handleUpdate,
                "row-del": _vm.handleDel
              },
              scopedSlots: _vm._u([
                {
                  key: "status",
                  fn: function(scope) {
                    return [
                      _c("el-switch", {
                        attrs: { "inactive-value": 1, "active-value": 0 },
                        on: {
                          change: function($event) {
                            return _vm.change(scope.row)
                          }
                        },
                        model: {
                          value: scope.row.status,
                          callback: function($$v) {
                            _vm.$set(scope.row, "status", $$v)
                          },
                          expression: "scope.row.status"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "menuForm",
                  fn: function(ref) {
                    var row = ref.row
                    var index = ref.index
                    var type = ref.type
                    return [
                      type == "add"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-circle-plus-outline",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowSave()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Hold")))]
                          )
                        : _vm._e(),
                      type == "edit"
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-circle-check",
                                size: "small"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.$refs.crud.rowUpdate()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$t("Hold")))]
                          )
                        : _vm._e(),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            icon: "el-icon-circle-close",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              return _vm.$refs.crud.closeDialog()
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      )
                    ]
                  }
                },
                {
                  key: "menu",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-edit"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.editShow(scope.row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("Edit")) +
                              "\n                "
                          )
                        ]
                      ),
                      _c(
                        "el-button",
                        {
                          staticClass: "none-border",
                          attrs: {
                            type: "text",
                            size: "small",
                            icon: "el-icon-delete"
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              return _vm.handleDel(scope.row)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(_vm.$t("Delete")) +
                              "\n                "
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            },
            [
              _c(
                "template",
                {
                  staticStyle: { width: "calc(100% - 80px)" },
                  slot: "menuLeft"
                },
                [
                  _c(
                    "el-row",
                    { staticClass: "topFromSearch", attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { xs: 24, sm: 16, md: 16, lg: 19, xl: 20 } },
                        [
                          _c(
                            "el-col",
                            {
                              staticClass: "formDiv",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c(
                                "span",
                                {
                                  style:
                                    _vm.status == ""
                                      ? "top: 50%;left: 50%;transform: translate(-50%, -50%);"
                                      : "top: -10px;left: 20px;transform: translate(0, 0);",
                                  attrs: { id: "StatusSpan" }
                                },
                                [_vm._v(_vm._s(_vm.$t("STATE")) + ":")]
                              ),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { clearable: "", placeholder: "" },
                                  on: {
                                    focus: function($event) {
                                      return _vm.focusDiv($event)
                                    },
                                    blur: function($event) {
                                      return _vm.blurDiv($event)
                                    },
                                    change: _vm.Statuschang
                                  },
                                  model: {
                                    value: _vm.status,
                                    callback: function($$v) {
                                      _vm.status = $$v
                                    },
                                    expression: "status"
                                  }
                                },
                                [
                                  _c(
                                    "el-option",
                                    {
                                      attrs: {
                                        label: _vm.$t("NORMAL"),
                                        value: "0"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("NORMAL")))]
                                  ),
                                  _c(
                                    "el-option",
                                    {
                                      attrs: {
                                        label: _vm.$t("DISABLE"),
                                        value: "1"
                                      }
                                    },
                                    [_vm._v(_vm._s(_vm.$t("DISABLE")))]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            {
                              staticClass: "formDiv",
                              attrs: { xs: 24, sm: 12, md: 8, lg: 6, xl: 6 }
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("CLASSIFICATION NAME OR CODE")
                                  ) + ":"
                                )
                              ]),
                              _c("el-input", {
                                on: {
                                  focus: function($event) {
                                    return _vm.focusDiv($event)
                                  },
                                  blur: function($event) {
                                    return _vm.blurDiv($event)
                                  }
                                },
                                model: {
                                  value: _vm.name,
                                  callback: function($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        {
                          staticStyle: {
                            "padding-top": "10px",
                            "text-align": "right",
                            "align-self": "flex-end"
                          },
                          attrs: { xs: 24, sm: 8, md: 8, lg: 5, xl: 4 }
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "iconfont tianjia",
                                size: "mini",
                                title: _vm.$t("New"),
                                plain: ""
                              },
                              on: { click: _vm.addShow }
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("New")))]
                          ),
                          _c(
                            "el-button",
                            {
                              attrs: {
                                title: _vm.$t("Refresh"),
                                icon: "iconfont shuaxin",
                                size: "mini",
                                plain: ""
                              },
                              on: { click: _vm.refreshChange }
                            },
                            [_vm._v("  " + _vm._s(_vm.$t("Refresh")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }