<template>
    <div class="pvhFactory">
        <basic-container>
            <el-aside width="20%" style="height:80vh;float: left;">
                <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick" v-loading="loading" :element-loading-text="$t('Loading material classification')" default-expand-all></el-tree>
            </el-aside>
            <timp ref="timp" style="width: 80%;float: left;" />
        </basic-container>
    </div>
</template>
<script>
import timp from "@/views/mtr/mtrlistTable";
import {getTree} from "@/api/mtrList"
export default {
    components: {timp},
    data() {
        return {
            loading: true,
            data : [{name: this.$t('All classification'),id: "PID",ChildNodes:[]}],
            defaultProps: {
                label: "name",
                value: "id",
                children: "ChildNodes"
            }
        }
    },

    created() {
        this.list()
    },

    methods: {
        list() {
                getTree().then(res => {
                this.data[0].ChildNodes = res.data.data;
                let obj = {name: this.$t('Unclassified'),id: '2'};
                this.data[0].ChildNodes.push(obj)
            }).then(() => {
                this.loading = false;
            });
        },
        handleNodeClick(node) {
            this.$refs.timp.clickList(node.id);
            console.log(node)
        }
    }
}
</script>
<style scoped>
    /deep/ .basic-container .el-card__body .el-card__body {
        padding: 0px !important;
    }
</style>