<template>
    <div class="pvhFactory">
        <basic-container>
            <el-dialog :title="$t('ADD')"
                       :visible.sync="addVisible"
                       fullscreen
                       :append-to-body="true">
                <el-form :model="addForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item :label="$t('CODE')" prop="code">
                        <el-input v-model="addForm.code"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('NAME')" prop="name">
                        <el-input v-model="addForm.name"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('INVENTORY TYPE')" prop="type">
                        <el-select v-model="addForm.type" clearable>
                            <el-option v-for="item in inventoryTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('CLASSIFICATION VALUE')" prop="scaleFactor">
                        <el-input v-model="addForm.scaleFactor"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('Product Classification Group')" prop="properties">
                        <el-select v-model="addForm.productCategoryTypeId" :placeholder="$t('Pleaseselect')" clearable>
                            <el-option v-for="item in typeList"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('Material Properties')" prop="properties">
                        <el-select v-model="addForm.propertieList" multiple :placeholder="$t('Pleaseselect')" clearable>
                            <el-option v-for="item in properties"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addVisible = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" @click="addSubmit">{{$t('Yes')}}</el-button>
                </div>
            </el-dialog>
            <el-dialog :title="$t('Edit')"
                       :visible.sync="editVisible"
                       fullscreen
                       :append-to-body="true">
                <el-form :model="editForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item :label="$t('CODE')" prop="code">
                        <el-input v-model="editForm.code"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('NAME')" prop="name">
                        <el-input v-model="editForm.name"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('INVENTORY TYPE')" prop="type">
                        <el-select v-model="editForm.type" clearable>
                            <el-option v-for="item in inventoryTypeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('CLASSIFICATION VALUE')" prop="scaleFactor">
                        <el-input v-model="editForm.scaleFactor"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('Product Classification Group')" prop="properties">
                        <el-select v-model="editForm.productCategoryTypeId" :placeholder="$t('Pleaseselect')" clearable>
                            <el-option v-for="item in typeList"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item :label="$t('Material Properties')" prop="properties">
                        <el-select v-model="editForm.propertieList" multiple :placeholder="$t('Pleaseselect')">
                            <el-option v-for="item in properties"
                                       :key="item.id"
                                       :label="item.name"
                                       :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="editVisible = false">{{$t('Cancel')}}</el-button>
                    <el-button type="primary" @click="editSubmit">{{$t('Yes')}}</el-button>
                </div>
            </el-dialog>
            <avue-crud :option="tableOption"
                       :data="tableData"
                       :table-loading="tableLoading"
                       :page.sync="page"
                       ref="crud"
                       @current-change="currentChange"
                       @size-change="sizeChange"
                       @selection-change="selectionChange"
                       @refresh-change="refreshChange"
                       @row-save="handleSave"
                       @row-update="handleUpdate"
                       @row-del="handleDel">
                <template slot="menuLeft" style="width: calc(100% - 80px);">
                    <el-row :gutter="20" class="topFromSearch">
                        <el-col :xs="24" :sm="16" :md="16" :lg="19" :xl="20" >
                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                <span id="StatusSpan" :style="status ==''?'top: 50%;left: 50%;transform: translate(-50%, -50%);':'top: -10px;left: 20px;transform: translate(0, 0);' ">{{$t('STATE')}}:</span>
                                <el-select clearable v-model="status" @focus="focusDiv($event)" @blur="blurDiv($event)" placeholder="" @change="Statuschang" style="width:100%;">
                                    <el-option :label="$t('NORMAL')" value="0">{{$t('NORMAL')}}</el-option>
                                    <el-option :label="$t('DISABLE')" value="1">{{$t('DISABLE')}}</el-option>
                                </el-select>
                            </el-col>
                            <el-col class="formDiv" :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                <span>{{$t('CLASSIFICATION NAME OR CODE')}}:</span>
                                <el-input v-model="name" @focus="focusDiv($event)" @blur="blurDiv($event)"></el-input>
                            </el-col>
                        </el-col>
                        <el-col :xs="24" :sm="8" :md="8" :lg="5" :xl="4" style="padding-top: 10px; text-align: right; align-self: flex-end;">
                            <el-button type="primary" icon="iconfont tianjia" size="mini" :title="$t('New')" plain @click="addShow"> {{$t('New')}}</el-button>
                            <el-button :title="$t('Refresh')" icon="iconfont shuaxin" size="mini" plain @click="refreshChange">  {{$t('Refresh')}}</el-button>
                        </el-col>
                    </el-row>
                </template>
                <template slot="status" slot-scope="scope">
                    <el-switch v-model="scope.row.status" :inactive-value="1" :active-value="0"
                               @change="change(scope.row)">
                    </el-switch>
                </template>
                <template slot-scope="{row,index,type}" slot="menuForm">
                    <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" v-if="type=='add'" @click="$refs.crud.rowSave()">{{$t("Hold")}}</el-button>
                    <el-button type="primary" icon="el-icon-circle-check" size="small" v-if="type=='edit'" @click="$refs.crud.rowUpdate()">{{$t("Hold")}}</el-button>
                    <el-button icon="el-icon-circle-close" size="small" @click="$refs.crud.closeDialog()">{{$t("Cancel")}}</el-button>
                </template>
                <template slot="menu" scope="scope">
                    <el-button type="text"
                               size="small"
                               icon="el-icon-edit"
                               class="none-border"
                               @click.stop="editShow(scope.row)">
                        {{$t("Edit")}}
                    </el-button>
                    <el-button type="text"
                               size="small"
                               icon="el-icon-delete"
                               class="none-border"
                               @click.stop="handleDel(scope.row)">
                        {{ $t("Delete") }}
                    </el-button>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>
import {list,add,edit,remove,changeStatus,getInventoryType,getProperties,getPropertyTypeList} from "@/api/mtrList"
export default {
    watch:{
        mtrId: function(value) {
            console.log(value);
           this.addshow = value == 2 ? false: true;
        },
      addVisible(New) {
          if (New) {
            this.$refs['ruleForm'].resetFields();
            this.addForm = this.$options.data.call(this).addForm
          }
      }
    },
    data() {
        return {
            addshow: true,
            typeList: [],
            properties: [],
            addForm: {
              code: null,
              name: null,
              type: null,
              propertieList: [],
              productCategoryTypeId: null,
              scaleFactor: null
            },
            editForm: {
              id: null,
              code: null,
              name: null,
              type: null,
              propertieList: [],
              productCategoryTypeId: null,
              scaleFactor: null
            },
            rules: {
              code: [
                { required: true, message: this.$t('PLEASE ENTER THE CODE'), trigger: 'blur' },
              ],
              name: [
                { required: true, message: this.$t('PLEASE ENTER A NAME'), trigger: 'blur' },
              ],
              type: [
                { required: true, message: this.$t('PLEASE ENTER INVENTORY TYPE'), trigger: 'blur' },
              ],
              scaleFactor: [
                { required: true, message: this.$t('PLEASE ENTER A CLASSIFICATION VALUE'), trigger: 'blur' },
              ]
            },
            inventoryTypeList: [],
            addVisible: false,
            editVisible: false,
            tableLoading: false,
            mtrId: "",
            name: "",
            status: "0",
            page: {
                pageSize: 10,
                pagerCount: 5,
                total: 0,
                pageCurrent: 1
            },
            disabled: true,
            tableData: [],
            tableOption: {
                refreshBtn: false,
                header: true,
                addBtn: false,
                delBtn: false,
                editBtn: false,
                menuWidth: 200,
                dialogWidth: 500,
                labelWidth: 120,
                selection: true,
                tip: false,
                columnBtn: false,
                dialogFullscreen: true,
                addBtnText: this.$t('ADD'),
                delBtnText: this.$t('DELETE'),
                editBtnText: this.$t('Edit'),
                emptyText: this.$t('No Data'),
                menuTitle: this.$t('OPERATION'),
                editTitle: this.$t('Edit'),
                addTitle: this.$t('Add'),
                saveBtn: false,
                updateBtn: false,
                cancelBtn: false,
                column: [{
                    label: this.$t('CODE'),
                    prop: "code",
                    placeholder: this.$t('PLEASE ENTER THE CODE'),
                    span: 24,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER THE CODE'),
                        trigger: "blur"
                    }]
                },{
                    label: this.$t('NAME'),
                    prop: "name",
                    placeholder: this.$t('PLEASE ENTER A NAME'),
                    span: 24,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER A NAME'),
                        trigger: "blur"
                    }]
                }, {
                    label: this.$t('INVENTORY TYPE'),
                    type: "select",
                    placeholder: this.$t('PLEASE SELECT INVENTORY TYPE'),
                    hide: true,
                    dicUrl: this.businessUrl + "dict/selectDictByCategoryId?categoryId=inventoryType",
                    props: {
                        label: "name",
                        value: "id"
                    },
                    prop: "type",
                    span: 24,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER INVENTORY TYPE'),
                        trigger: "blur"
                        }],
                },
                {
                    label: this.$t('INVENTORY TYPE'),
                    display: false,
                    type: "select",
                    prop: "dictName",

                },
                {
                    label: this.$t('CLASSIFICATION VALUE'),
                    prop: "scaleFactor",
                    placeholder: this.$t('PLEASE ENTER A CLASSIFICATION VALUE'),
                    span: 24,
                    rules: [{
                        required: true,
                        message: this.$t('PLEASE ENTER A CLASSIFICATION VALUE'),
                        trigger: "blur"
                    }],
                    width:120,
                },
                {
                    label: this.$t('CREATE USER'),
                    editDisplay: false,
                    addDisplay: false,
                    prop: "userName"
                }, {
                    label: this.$t('ENABLE STATUS'),
                    slot: true,
                    editDisplay: false,
                    addDisplay: false,
                    prop: "status",

                }]
            }
        }
    },
    created() {
      getInventoryType().then(res =>{
        this.inventoryTypeList = res.data.data;
      });
      getPropertyTypeList().then(res =>{
        this.typeList = res.data.data;
      });
      getProperties().then(res =>{
        this.properties = res.data.data;
      })
    },
        methods: {
            focusDiv(obj) {//搜索框获取焦点
                if (obj.currentTarget === undefined) {
                    obj.$el.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                } else {
                    obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },//搜索框失去焦点
            blurDiv(obj) {
                if (obj.currentTarget === undefined) {
                    if (obj.value === "") {
                        obj.$el.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                } else {
                    if (obj.target.value == "") {
                        obj.currentTarget.parentElement.previousElementSibling.style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                    }
                }

            },//表格行样式
            Statuschang() {
                if (this.otherStatusVal === 0) {
                    document.querySelector("#StatusSpan").style.cssText = "top: 50%;left: 50%;transform: translate(-50%, -50%);font-size:13px;color:#606266";
                } else {
                    document.querySelector("#StatusSpan").style.cssText = "top: -10px;left: 20px;transform: translate(0, 0);font-size:13px;color:#606266;";
                }
            },
        addCancle(){
          this.addForm = {
                code: null,
                name: null,
                type: null,
                scaleFactor: null
          };
          this.addVisible = false;
        },
        editCancle(){
          this.editForm = {
                code: null,
                name: null,
                type: null,
                scaleFactor: null
          };
          this.editVisible = false;
        },
        editShow(row){
          this.editForm.id = row.id;
          this.editForm.code = row.code;
          this.editForm.name = row.name;
          this.editForm.type = row.type;
          this.editForm.productCategoryTypeId = row.productCategoryTypeId;
          this.editForm.propertieList = row.propertieList;
          this.editForm.scaleFactor = row.scaleFactor;
          this.editVisible = true;
        },
        editSubmit(){
          edit(this.editForm,this.mtrId).then((res) => {
            if (res.data.code == "0000") {
              this.$message({
                type: "success",
                message: this.$t('EDIT SUCCEEDED')
              });
              this.list();
              this.editVisible = false;
              this.$parent.list();
            }
          });
        },
        addShow(){
          this.addVisible = true;
        },
        addSubmit(){
          console.log(this.addForm);
          console.log('新增')
          if (!this.mtrId) {
            this.$message({
              type: "warning",
              message: this.$t('PLEASE SELECT THE SUPERIOR CATEGORY ON THE LEFT FIRST')
            });
            return;
          }
          add(this.addForm,this.mtrId).then((res) => {
            if(res.data.code == "0000"){
              this.$message({
                type: "success",
                message: this.$t('AddSuccess'),
              });
              this.list();
              this.addVisible = false;
            }
          });
        },
        currentChange(pageCurrent) {
          this.page.pageCurrent = pageCurrent;
          this.list();
        },
        sizeChange(pageSize) {
          this.page.pageCurrent = 1;
          this.page.pageSize = pageSize;
          this.list();
        },
        clickList(mtrId) {
            this.mtrId = mtrId;
            list(this.status, this.name, this.page.pageCurrent,this.page.pageSize,this.mtrId).then(res => {
                this.page.total = 0;
                this.page.total = Number(res.data.data.total);
                this.tableData = res.data.data.items;
            })
        },
        handleDel(row) {
            this.$confirm(this.$t('IsDel'), this.$t('Tips'), {
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('Cancel'),
                type: 'warning'
            }).then(() => {
                remove(row.id).then((res) => {
                     if (res.data.code == "0000") {
                        this.$message({
                        type: "success",
                        message: this.$t('DeleteSuccess'),
                        });
                     }
                    this.list();
                })
            })
        },
        handleUpdate(row,index,done) {
            edit(row,this.mtrId).then((res) => {
                 if (res.data.code == "0000") {
                this.$message({
                  type: "success",
                    message: this.$t('EDIT SUCCEEDED')
                });
              }
                this.list();
                done();
            });
            this.$parent.list();
        },
        handleSave(row,done) {

        },
        change(row) {
            changeStatus(row).then(() => {
                this.$message({
                    message: this.$t('UpdateSuccess'),
                    type: "success"
                })
            })
        },
        selectionChange(list) {
            console.log(list);
            list != "" ? this.disabled = false : this.disabled = true;
        },
        refreshChange() {
          this.list();
        },
        list(){
            list(this.status, this.name, this.page.pageCurrent,this.page.pageSize,this.mtrId).then(res => {
              if (res.data.data == null) {
                this.tableData = [];
                this.page.total = 0;
              } else {
                this.tableData = res.data.data.items;
                this.page.total = res.data.data.total;
              }
        })
        },
        envText: function() {
            return this.env
        }
  }
}
</script>
<style scoped>
    .el-select-dropdown__item {
        padding-left: 0px;
    }
</style>
